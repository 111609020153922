@import "~antd/dist/antd.less";

.layout-header {
  color: white;
  background-color: #0068b7;
  display: flex;
  justify-content: space-between;
}

.layout-logo {
  width: 200px;
  height: 64px;
}

.layout-sider {
  height: calc(100vh - 64px);
  background-color: #34444c;
}

.layout-content {
  height: calc(100vh - 64px);
  padding: 16px;
}

.plan-item-container {
  height: 100%;
  text-align: center;
  border: 1px solid #ededed;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
  padding: 24px;
  background-color: white;
}

.plan-item-footer {
  margin-top: auto;
}

.plan-list {
  text-align: left;
  list-style-type: none;
}

.plan-list-icon {
  margin-right: 10px;
}

.plan-list-item {
  line-height: 35px;
}

.login-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  background: linear-gradient(-135deg, #c850c0, #4158d0);
}

.login-inner {
  margin: auto;
  padding: 48px;
  width: 400px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px;
}

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@primary-color: #4158d0;@border-radius-base: 4px;